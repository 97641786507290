import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import axios from 'axios'

import Layout from './layout/index'
import Home from "./pages/Home";
import About from "./pages/About"
import Admin from './admin/Admin'
import AdminLogin from './admin/AdminLogin'
import AdAppForm from "./admin/AdAppForm";
import AdCreatePost from "./admin/AdCreatePost";
import AdRegUsers from "./admin/AdRegUsers";
import AdContactQuery from "./admin/AdContactQuery";
import AdNotification from "./admin/AdNotification";
import Register from "./components/Register";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import CandidateDashboard from "./components/CandidateDashboard";
import Form from "./components/Form";
import ExamFeeCheck from "./components/ExamFeeCheck";
import ApplicationPDF from "./components/ApplicationPDF";
import ViewApplication from "./components/ViewApplication";
import Contact from "./pages/Contact";
import Service from "./pages/Service";
import CareerAt from './pages/CareerAt'
import VisionandValue from './pages/VisionandValue'
import WhyAviation from "./pages/WhyAviation";
import ImportantNotice from "./pages/ImportantNotice";
import LatestNewALLIndia from "./pages/LatestNewALLIndia";
import Syllabus from "./pages/Syllabus";
import GreenfieldsInfo from "./components/pages/GreenfieldsInfo";
import Howtoapply from "./components/pages/Howtoapply";
import Noticeinfo from "./pages/Noticeinfo";
import AdmitCardWrapper from "./pages/AdmitCardWrapper";
import ResultWrapper from "./pages/ResultWrapper";

//SEO Pages Imports
import Ahmedabad from "./seopages/Ahmedabad";
import Banglore from "./seopages/Banglore";
import Bihar from "./seopages/Bihar";
import Delhi from "./seopages/Delhi";
import Hyderabad from "./seopages/Hyderabad";
import Kolkata from "./seopages/Kolkata";
import Mumbai from "./seopages/Mumbai";
import Punjab from "./seopages/Punjab";
import Tamilnadu from "./seopages/Tamilnadu";
import Uttarpradesh from "./seopages/Uttarpradesh";

import Error from './components/Error'
import Result from "./pages/Result";

// "antd": "^4.23.5",


function App() {

  const [formState, setFormState] = useState();

  const checkApplicationStatus = useCallback(async()=>{
    try{
        const res = await axios.get(`${process.env.REACT_APP_HOSTURL}/api/v1/changeappformstattus`);
       // console.log(res.data);
        setFormState(res.data.data[0].applicationStatus);
    }catch(err){
     //   console.log(err);
    }
},[])

//console.log('form state', formState);

const loggedInUser  = useSelector((state)=> state.user)
// console.log(loggedInUser.user);
let user;

if(!loggedInUser || loggedInUser === null || loggedInUser.user === null || loggedInUser === ''){
  user = false
}else{
  user = true
}

let isValid;

if(formState === true && user === true ){
  isValid = true
  //console.log('isValid', isValid);
  //console.log('user', user);
}else{
  isValid = false
  //console.log('isValid', isValid);
}

useEffect(()=>{
  checkApplicationStatus()
},[checkApplicationStatus])


// queueMicrotask (console.log.bind (console,  "%cBhartiya Aviation Services", "color:orange;font-family:system-ui;font-size:4rem;-webkit-text-stroke: 1px black;font-weight:bold"));

// queueMicrotask (console.log.bind (console,  '%c⚠️Warning!⚠️','color: red; font-family: sans-serif; text-decoration: underline;font-weight:bold; font-size: 24px;'));

// queueMicrotask (console.log.bind (console,  '%cThe candidates are informed that the Bhartiya Aviation Services has no relation with the currency demanded by any particular person for passing the examination or passing the interview, if any candidate is in favor of it, then the candidate himself will be responsible for it.','color: red; font-family: sans-serif; text-decoration: underline;font-weight:bold; font-size: 19px;'));


  return ( 
    <BrowserRouter>
    <Layout>
    <Routes> 
    <Route path='/' element={ <Home/>} />
    <Route path='/aboutus' element={ <About/>} />
    <Route path='/services' element={ <Service/>} />
    <Route path='/careeratairport' element={ <CareerAt/>} />
    <Route path='/visionvalue' element={ <VisionandValue/>} />
    <Route path='/whyaviation' element={ <WhyAviation/>} />
    <Route path='/imporatntnotice' element={ <ImportantNotice/>} />
    <Route path='/latestnewsupdate' element={ <LatestNewALLIndia/>} />
    <Route path='/syllabus' element={ <Syllabus/>} />
    <Route path='/greenfieldinfo' element={ <GreenfieldsInfo/>} />
    <Route path='/howtoapply' element={ <Howtoapply/>} />
    <Route path='/notice' element={ <Noticeinfo/>} />
    <Route path="/admitcarddownload" element={<AdmitCardWrapper/>}/>
    {/* <Route path="/testadmitcard" element={<AdmitCardWrapper/>}/> */}
    <Route path="/result" element={<ResultWrapper/>}/>

    <Route path='/basadmincontrol' element={ <Admin/>} />
    <Route path='/admin/login' element={ <AdminLogin/>} />
    <Route path='/dbappforms' element={ <AdAppForm/>} />
    <Route path='/dbcreatepost' element={ <AdCreatePost/>} />
    <Route path='/allregusers' element={ <AdRegUsers/>} /> 
    <Route path='/dbcontactquery' element={ <AdContactQuery/>} />
    <Route path='/dbnotify' element={ <AdNotification/>} />
    <Route path='/register-now' element={ <Register/>} />
    <Route path='/login' element={ <Login/>} />
    <Route path='/forgotaccount' element={ <ForgotPassword/>} />
    <Route path='/contactus' element={ <Contact/>} />
    <Route path='/myapplication/:id' element={ <ViewApplication/>} />
    {/* <Route path='/feecheck' element={isValid ? <ExamFeeCheck/> : <Register/> } /> */}
    <Route path='/candidatedb' element={user ? <CandidateDashboard/> : <Login/>} />
    <Route path='/application-form' element={ isValid ?  <Form/> : <Register/>} />

   
    <Route path='/applicationpdf/:id' element={ user ?  <ApplicationPDF/> : <Login/>} />

    {/* SEO_Pages Routes */}  

    <Route path='/indigo-careers-and-airport-job-in-ahmedabad' element={ <Ahmedabad/>} />
    <Route path='/indigo-careers-and-airline-job-in-banglore' element={ <Banglore/>} />
    <Route path='/indigo-careers-and-airline-jobs-in-bihar' element={ <Bihar/>} />
    <Route path='/airline-careers-and-airport-jobs-in-delhi' element={ <Delhi/>} />
    <Route path='/air-india-careers-and-airport-jobs-in-hyderabad' element={ <Hyderabad/>} />
    <Route path='/indigo-careers-and-airline-job-in-kolkata' element={ <Kolkata/>} />
    <Route path='/indigo-careers-and-airport-job-in-mumbai' element={ <Mumbai/>} />
    <Route path='/indigo-careers-and-airport-job-in-punjab' element={ <Punjab/>} />
    <Route path='/airline-careers-and-airport-job-in-tamilnadu' element={ <Tamilnadu/>} />
    <Route path='/indigo-careers-and-airline-jobs-in-uttarpradesh' element={ <Uttarpradesh/>} />


    <Route path='*' element={ <Error/>} />
    </Routes>
    </Layout>
    
    </BrowserRouter>
  );
}

export default App;
