import React, { useState } from 'react';
import { Radio } from 'antd';

import AdmitCard from './AdmitCard'
import AdmitCardLoader from './AdmitCardLoader'


const optionsWithDisabled = [
    {
      label: 'Ground Staff / CSA',
      value: 'csa',
    },
    {
      label: 'Loader / Housekeeping',
      value: 'loader',
      disabled: true,
    }
  ];

const AdmitCardWrapper = () => {

    const [value4, setValue4] = useState('csa');
      const onChange4 = ({ target: { value } }) => {
        setValue4(value);
      };

  return (
    <>
        <div className='container'>
        <div className='text-center my-4 d-print-none'>
              <h3 className='admit_crd_heading '>Download Admit Card</h3>
            <p className='text-center text-muted m-2'><b>Note:</b> All candidates are informed that the admit card for the profiles of Airport Ground Staff / CSA and Loader / Housekeeping admit card is available on the website of Bhartiya Aviation Services. All candidates are requested to download their admit card as per their profiles.</p>
        </div>
        <div className='text-center'>
            <p className='d-print-none'><b><em>Please select profile </em></b></p>
        <Radio.Group
        options={optionsWithDisabled}
        onChange={onChange4}
        value={value4}
        optionType="button"
        buttonStyle="solid"
        className='d-print-none'
      />

      <div>
        {
        value4 === 'loader' ?  <AdmitCardLoader/> : <AdmitCard/>
        }
      </div>
        </div>


        
        </div>
    </>
  )
}

export default AdmitCardWrapper