import React from 'react'
import './header.css'
import { MdMailOutline,MdLocalPhone } from "react-icons/md";
import { BsFacebook,BsInstagram,BsLinkedin } from "react-icons/bs";
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import {logoutuser} from '../../context/userSlice'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MobileNavbar from '../../components/MobileNavbar';

const Header = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const loggedInUser  = useSelector((state)=> state.user)
  //console.log(loggedInUser)

const logUser = loggedInUser.user
//console.log(logUser)

  const logOutHandler = (e)=>{
    try{
      
      e.preventDefault()
      dispatch(logoutuser())
      navigate('/')

    }catch(err){
      //console.log(err)
    }
  }




  return (
    <>
      <div className='container-fluid topbar-main-div d-print-none'>
        <div className='container-sm topbar-inner'>
            <div className='top-bar-inner-left'>
              <div className='top-bar-left-email-div'>
                  <MdMailOutline/>
                  <p> <a href="mailto:info@bhartiyaaviation.in">info@bhartiyaaviation.in</a> </p>
                  <span></span>
              </div>
              <div className='top-bar-left-phone-div'>
              <MdLocalPhone/>
              <p><a href="tel:+918447588447">+91 8447-58-8447</a></p>
              </div>
            </div>

            <div className='top-bar-inner-right'>
              <div className='main-content-div'>
                <Link to={'/examfeecheck'}>
                <p>Main Content</p>
                </Link>
              </div>
              <span id='main-cnt-divider'></span>
              <div className='social-icons-div'>
                <a href="https://www.linkedin.com/in/bhartiya-aviation-services-092417247/">
                <BsLinkedin className='twitter-icon'/>
                </a>
                <a href="https://www.instagram.com/bhartiyaaviationservices/">
                <BsInstagram className='insta-icon'/>
                </a>
                <a href="https://www.facebook.com/bhartiyaaviationservice">
                <BsFacebook className='facebook-icon'/>
                </a>
              </div>
              <span id='user-name-left-divider'></span>
              <div className='login-reg-div'>
               {logUser ? (
                <>
                <Link to={'/candidatedb'}>
                <p>{!logUser ? 'Dashboard' : logUser?.canName?.toUpperCase()}</p>
                </Link>
                <span></span>
                <p onClick={logOutHandler}>LOGOUT</p>
                </>
               ):(
                <>
                <Link to={'/login'}>
                <p>LOGIN</p>
                </Link>
                <span></span>
                <Link to={'/register-now'}>
                <p>REGISTER</p>
                </Link>
                </>
               )}
              </div>
            </div>
        </div>
      </div>

    <Link to={'/'}>
      <div className='container  banner-main-div d-print-none'>
          <img className='' src="/images/BAS-Banner.jpeg" alt="" />
      </div>
    </Link>

    <div className=' mobile-view-menu-bg-div'>
        <MobileNavbar/>
    </div>
    </>
  )
}

export default Header